p {
  padding: 0;
  margin: 0;
  font-size: 16px;
}
input {
  padding: 0;
  margin: 0;
  font-size: 16px;
}
label {
  padding: 0;
  margin: 0;
  font-size: 16px;
}
h1 {
  padding: 0;
  margin: 0;
  font-size: 36px;
  font-weight: normal;
}

h2 {
  padding: 0;
  margin: 0;
  font-size: 30px;
  font-weight: normal;
}

h3 {
  padding: 0;
  margin: 0;
  font-size: 24px;
  font-weight: normal;
}

h4 {
  padding: 0;
  margin: 0;
  font-size: 18px;
  font-weight: normal;
}

h5 {
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-weight: normal;
}

h6 {
  padding: 0;
  margin: 0;
  font-size: 12px;
  font-weight: normal;
}
