@use "src/style/main" as *;

.VerifyPhoneNumberButton {
  &__container {
    display: flex;
    align-items: center;
    border-radius: 25px;
    padding: 5px 15px 5px 5px;
    margin-right: 10px;
    border: 0.5px solid rgba(19, 35, 66, 0.3);

    &:hover {
      cursor: pointer;
    }
  }

  &__circle {
    width: 35px;
    height: 35px;
    background-color: #e98138;
    border-radius: 50%;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__avatar {
    width: 22px;
    height: 22px;
  }

  &__verify {
    color: $secondary-font-color;
    font-size: 10px;
    text-transform: uppercase;
    line-height: 94%;
    letter-spacing: 0.64px;
  }
}

@include mobile {
  .VerifyPhoneNumberButton {
  }
}
