@use "src/style/main" as *;

.PublicApplyForm {
  height: 100%;

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 10%;
  }
  &__body {
    height: 90%;
  }

  &__question {
    color: $primary-font-color;
    font-family: "Manrope-Bold", sans-serif;
    font-size: 20px;
    margin-bottom: 10px;
    text-align: start;
  }

  &__button-front-text {
    color: $primary-font-color;
    font-family: "Manrope-Bold", sans-serif;
    font-size: 20px;
    text-align: start;
  }

  &__login-button {
    color: $primary-font-color;
    background: $background-color;
    border: 1px solid $primary-font-color;
    font-family: "Manrope-Bold", sans-serif;
    font-size: 20px;
    text-align: start;
    //margin-top: 20px;
  }

  &__info-tip {
    background: $white-color;
    font-family: "Manrope-Bold", sans-serif;
    color: $primary-font-color;
    display: flex;
    //justify-content: center;
    //align-items: center;
    align-items: center;
    gap: 20px;
    justify-content: space-between;
    //border-radius: 12px;
    padding: 20px 30px 20px 30px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
    //position: absolute;
    margin-bottom: 30px;
    //right: 30px;

    &--icon {
      width: 50px;
      height: 50px;
    }
    &--text {
      font-size: 16px;
      padding-left: 5px;
      //white-space: nowrap;
    }
    @media (max-width: 600px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__description {
    color: $secondary-font-color;
    font-family: "Manrope-Bold", sans-serif;
    font-size: 20px;
    margin-bottom: 40px;
    text-align: center;
  }

  &__personal-details-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  &__experience-item {
    padding: 20px 0 15px;
    border-bottom: 0.2px solid rgba(220, 220, 220, 0.2);
    font-family: "Manrope-Bold", sans-serif;
    color: #b2b2b2;
    p {
      font-size: 14px;
      line-height: 13px;
      font-family: "Manrope-Medium", sans-serif;
      color: #b2b2b2;
      margin-bottom: 8px;
    }
  }
  &__experience-item-title-row {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 20px;
    margin-bottom: 8px;
  }
  &__experience-item-title {
    font-size: 16px;
    line-height: 15px;
    font-family: "Manrope-Bold", sans-serif;
    color: #132342;
  }

  &__no-experience-item-title {
    font-size: 14px;
    line-height: 15px;
    font-family: "Manrope-Medium", sans-serif;
    color: #b2b2b2;
    margin-bottom: 8px;
  }
  &__experience-item-date {
    font-size: 14px;
    line-height: 13px;
    font-family: "Manrope-Medium", sans-serif;
    color: #b2b2b2;
    margin-bottom: 8px;
  }
}

.PublicApplyDetails {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;

  &__body {
  }

  &__map {
    padding-top: 20px;
    width: 100%;
    height: 300px;

    &-error {
      text-align: right;
      margin: 5px 10px;
      font-family: "Manrope-Bold", sans-serif;
      color: $error-color;
    }
    &-description {
      text-align: left;
      margin: 5px 10px;
      font-family: "Manrope-Bold", sans-serif;
      color: $secondary-font-color;
      font-size: 14px;
    }
  }

  &__footer {
    @media (max-width: 600px) {
      padding-bottom: 100px;
    }
  }

  &__sectionOne {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 10px;
  }

  &__uploader {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    &-error {
      text-align: right;
      margin: 5px 10px 30px 10px;
      font-family: "Manrope-Bold", sans-serif;
      color: $error-color;
    }
    &-description {
      text-align: center;
      margin: 5px 10px;
      font-family: "Manrope-Bold", sans-serif;
      color: $secondary-font-color;
      font-size: 12px;
      padding-bottom: 20px;
    }
  }

  &__circle {
    width: 87px;
    height: 87px;
    border-radius: 50%;
    position: relative;
    border: 1px solid #ebebeb;
  }

  &__placeholder {
    width: 87px;
    height: 87px;
    border-radius: 50%;
    object-fit: cover;
  }
  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 87px;
    height: 87px;
    border-radius: 50%;
    object-fit: cover;
  }

  &__pen {
    position: absolute;
    bottom: -15px;
    right: -15px;
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
  }



  &__input {
    margin-bottom: 20px;
    flex-grow: 1;
    width: auto;
    &-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
    }
    &-button-text-row{
      padding-top: 10px;
      display: flex;
      justify-content: start;
      align-items: center;
      gap: 20px;
    }
    &-error {
      text-align: right;
      margin: 5px 10px;
      font-family: "Manrope-Bold", sans-serif;
      color: $error-color;
    }
    &-description {
      text-align: left;
      margin: 5px 10px;
      font-family: "Manrope-Bold", sans-serif;
      color: $secondary-font-color;
      font-size: 12px;
    }
  }

  &__textArea {
    height: 130px;

    &-error {
      text-align: right;
      margin: 5px 10px;
      font-family: "Manrope-Bold", sans-serif;
      color: $error-color;
    }
  }

  &__button {
  }
}

@keyframes tabBorderAnimation {
  from {
    width: 80%;
  }
  to {
    width: 100%;
  }
}

@include screen-2k {
  .PublicApplyDetails {
    &__map {
      height: 500px;
    }
  }
}
