@use "src/style/main" as *;

.Button {
  min-width: 50px;
  width: 100%;
  height: 71px;
  font-size: 16px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(200deg, #aee7ff -5.98%, #030ac0 60.4%);
  color: $white-color;

  &--disabled {
    opacity: 0.7;
    cursor: not-allowed !important;
    background: linear-gradient(
      200deg,
      $white-color -5.98%,
      $secondary-font-color 60.4%
    );
  }

  &__label {
    margin: 0 5px;
    white-space: nowrap;
    font-family: "Manrope-ExtraBold", sans-serif;
  }

  &__spinner {
    width: 20px;
    padding: 3px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: $white-color;
    --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
    mask: var(--_m);
    -webkit-mask-composite: source-out;
    mask-composite: subtract;
    animation: l3 1s infinite linear;
  }
  @keyframes l3 {
    to {
      transform: rotate(1turn);
    }
  }

  &__secondary {
    background: linear-gradient(
      200deg,
      $white-color -5.98%,
      $secondary-font-color 60.4%
    );
  }
  &__success {
    background: linear-gradient(
      200deg,
      $white-color -5.98%,
      $success-color 60.4%
    );
  }
  &__error {
    background: linear-gradient(
      200deg,
      $white-color -5.98%,
      $error-color 60.4%
    );
  }
  &__warning {
    background: linear-gradient(
      200deg,
      $white-color -5.98%,
      $warning-color 60.4%
    );
  }
}
