@use "src/style/main" as *;

.PrivateRoutesLayout {
  &__container {
    padding-top: 75px;
    padding-left: 240px;
    z-index: 5;
    display: flex;
    justify-content: center;
  }

  &__routes {
    padding: 20px;
    width: 100%;
    max-width: 1200px;
  }
}

@include tablet {
  .PrivateRoutesLayout {
    &__container {
      padding-left: 62px;
    }
  }
}

@include mobile {
  .PrivateRoutesLayout {
    &__container {
      padding-left: 0;
    }
  }
}
