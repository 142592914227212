@use "src/style/main" as *;

.SendEmail {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  &__left {
    width: 50%;
    height: 100%;
    background: $background-color;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-mobile {
      width: 100%;
    }

    &-container {
      height: 100%;
      width: 100%;
      max-width: 560px;
      padding: 0 20px;
    }
  }

  &__right {
    width: 50%;
    height: 100%;
    background: linear-gradient(
      208deg,
      $secondary-color -9.13%,
      $primary-color 97.4%
    );
    display: flex;
    justify-content: center;
    align-items: center;

    &-container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 15%;
    &-icon {
      width: 200px;
    }
  }

  &__form {
    height: 85%;
    display: flex;
    align-items: center;
    &-container {
      width: 100%;
    }

    &-title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      margin-bottom: 60px;
      padding-bottom: 40px;
      width: 100%;

      &--icon {
        width: 80px;
      }

      &--text {
        font-family: "Manrope-Bold", sans-serif;
        color: $primary-font-color;
        text-align: left;
        margin-left: 20px;
      }
    }

    &-descriptionOne {
      font-family: "Manrope-Bold", sans-serif;
      color: $primary-font-color;
      font-size: 20px;
      text-align: left;
      margin-bottom: 20px;

      & > span {
        text-decoration: underline;
      }
    }
    &-descriptionTwo {
      font-family: "Manrope-Bold", sans-serif;
      text-align: left;
      color: $secondary-font-color;
      margin-bottom: 70px;

      & > span {
        color: $warning-color;
      }
    }

    &-back {
      color: $primary-font-color;
      text-align: center;
      font-size: 14px;
      margin-top: 30px;
      cursor: pointer;
      text-decoration: underline;
    }
  }

  &__bigCircle {
    width: 100%;
    height: 100%;
    max-width: 600px;
    max-height: 660px;
    background-image: url("../../../src/assets/svg/big-circle-opacity.svg");
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__smallCircle {
    width: 100%;
    height: 100%;
    max-width: 450px;
    max-height: 520px;
    background-image: url("../../../src/assets/svg/small-circle-opacity.svg");
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;

    &-text {
      color: $white-color;
      font-size: 44px;
      font-family: "Manrope-Regular", sans-serif;
      text-align: left;
      line-height: 98%;
      padding-left: 30px;

      & > span {
        font-size: 85px;
        font-family: "Manrope-Bold", sans-serif;
      }
    }
  }
}

@include screen-2k {
  .SendEmail {
    &__form {
      &-container {
        margin-bottom: 30%;
      }
    }
  }
}
