@use "src/style/main" as *;

.ResetPasswordForm {
  display: flex;
  align-items: center;
  height: 100%;
  &__container {
    width: 100%;
  }
  &__title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    margin-bottom: 20px;
    padding-bottom: 30px;
    width: 100%;

    &-icon {
      width: 50px;
    }

    &-text {
      font-family: "Manrope-Bold", sans-serif;
      color: $primary-font-color;
      text-align: left;
      margin-left: 20px;
    }
  }

  &__description {
    font-family: "Manrope-Bold", sans-serif;
    color: $primary-font-color;
    font-size: 20px;
    text-align: left;
    margin-bottom: 30px;
  }

  &__input {
    margin-bottom: 20px;
    &-error {
      text-align: right;
      margin: 5px 10px;
      font-family: "Manrope-Bold", sans-serif;
      color: $error-color;
    }
  }

  &__button {
    margin-top: 30px;
  }

  &__back {
    color: $primary-font-color;
    text-align: center;
    font-size: 14px;
    margin-top: 30px;
    cursor: pointer;
    text-decoration: underline;
  }
}

@include screen-2k {
  .ResetPasswordForm {
    &__container {
      margin-bottom: 30%;
    }
  }
}
