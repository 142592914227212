@use "src/style/main" as *;

.FileUploader {
  border-radius: 23px;
  background: $white-color;
  padding: 20px;

  &__input {
    margin-bottom: 20px;
    margin-left: 5px;
    &-error {
      margin: 0 10px;
      font-family: "Manrope-Bold", sans-serif;
      color: $error-color;
      font-size: 14px;
    }

    &-label {
      font-family: "Manrope-Bold", sans-serif;
      font-size: 25px;
      color: $primary-font-color;
      margin-bottom: 40px;
      margin-left: 5px;
    }
    &-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      margin-bottom: 20px;
    }
  }

  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
