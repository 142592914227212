@use "src/style/main" as *;

.MessageModal {
 // position: absolute;
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 13px;
  z-index: 50;
  visibility: hidden;

  &--isVisible {
    visibility: visible;
  }

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    background: $white-color;
    margin: 0 20px;
    padding: 10px 20px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
    border-radius: 12px;

    &--success {
      background: $success-color;
    }
    &--error {
      background: $error-color;
    }
    &--warning {
      background: $warning-color;
    }
  }

  &__text {
    color: $black-color;
    font-family: "Manrope-Bold", sans-serif;
    margin: 0 20px;
    text-align: center;
    font-size: 16px;
  }
  &__close {
    width: 25px;
    height: 25px;

    &:hover {
      cursor: pointer;
    }
  }
}
