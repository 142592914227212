$primary-color: #030ac0;
$secondary-color: #aee7ff;

$primary-font-color: #132342;
$secondary-font-color: #b2b2b2;

$background-color: #f7f7f7;

$white-color: #fff;
$black-color: #000;

$success-color: #03c02d;
$error-color: #dd291e;
$warning-color: #ffb82e;
