@use "src/style/main" as *;

.CountrySelectBox {
  width: 100%;
  height: 51px;
  border-radius: 10px;
  background-color: $white-color;
  border: 0.1px solid rgba(19, 35, 66, 0.1);
  color: $primary-font-color;
  font-family: "Manrope-Bold", sans-serif;
  position: relative;
  box-shadow: 0px 0px 20px 4px #cecece40;
  margin-right: 20px;
  &:focus-within {
    border: 0.1px solid rgba(19, 35, 66, 0.1);
    color: $primary-font-color;
  }
  &__add-new {
    font-size: 14px;
    line-height: 14px;
    font-family: "Manrope-Regular", sans-serif;
    margin: 0;
    //padding: 16px;
    &:hover {
      background: #f9fafb;
      border-radius: 8px;
      cursor: pointer;
    }
  }

  &__item {
    font-size: 14px;
    line-height: 14px;
    font-family: "Manrope-Regular", sans-serif;
    margin: 0;
    padding: 16px;
    &:hover {
      background: #f9fafb;
      border-radius: 8px;
      cursor: pointer;
    }
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
    height: 100%;
    font-family: "Manrope-Regular", sans-serif;
    padding: 8px 16px;
    background-color: $white-color;
    &:focus-within {
      border: 0.1px solid rgba(19, 35, 66, 0.1);
      filter: drop-shadow(0px 0px 20px rgba(206, 206, 206, 0.1));
      color: $primary-font-color;
      transition: 0.3s;
    }
  }
  &__text {
    flex-grow: 1;
    //min-width: 200px;
    font-size: 16px;
    padding-right: 15px;
    color: $primary-font-color;
    font-family: "Manrope-Bold", sans-serif;
    display: inline-block; // Ensures it respects flex layout
    overflow: hidden;
    text-overflow: ellipsis; // Adds "..." if the text overflows
    white-space: nowrap; // Keeps text on a single line
    @include mobile {
      // remove min-width
      min-width: 0;
    }
  }
  &__input {
    width: 100%;
    font-size: 16px;
    padding: 0 20px 0 0 ;
    height: 71px;
    border-radius: 10px;
    color: $primary-font-color;
    outline: none;
    border: none;
    background: transparent;
    font-family: "Manrope-Bold", sans-serif;

    &--disabled {
      cursor: not-allowed;
    }

    &::placeholder {
      font-family: "Manrope-Bold", sans-serif;
      color: #b2b2b2;
    }
  }

  &__label {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-family: "Manrope-Bold", sans-serif;
    color: $primary-font-color;
  }
  &__placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-family: "Manrope-Bold", sans-serif;
    color: #b2b2b2;
  }

  &__icon {
    cursor: pointer;
    width: 16px;
  }

  &__items {
    margin-top: 6px;
    overflow-y: auto;
    max-height: 300px;
    width: 100%;
    position: absolute;
    left: 0;
    display: none;
    justify-content: center;
    border-radius: 8px;
    background: $white-color;
    color: $primary-font-color;
    font-family: "Manrope-Regular", sans-serif;
    padding: 0px, 8px, 0px, 8px;
    box-shadow: 0px 0px 20px 4px #cecece40;
    border: 0.1px solid rgba(19, 35, 66, 0.1);

    &.active {
      display: block;
      z-index: 10;
    }
  }

  &__item-container {
    line-height: 21px;
    overflow: hidden;
    width: 100%;
    background: transparent;
    color: $primary-font-color;
    padding: 4px 8px;
    &.activeLabel {
      background: #f9fafb;
    }
    &:hover {
      background: #f9fafb;
      cursor: pointer;
    }
  }
  &__button {
    width: 100%;
    border: 0.5px solid rgba(255, 255, 255, 0.2);
    border-radius: 27px;
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 5px 20px 5px 5px;

    &:hover {
      background: #f9fafb;
      border-radius: 8px;
      cursor: pointer;
    }

    &-round {
      background: $white-color;
      border-radius: 50%;
      width: 45px;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      &--plus {
        width: 20px;
        height: 20px;
      }
    }

    &-text {
      color: $white-color;
      font-family: "Manrope-Bold", sans-serif;
      text-transform: uppercase;
    }
  }
}

@include mobile {
  .CountrySelectBox {
    // height: 50px;

    &__items {
      margin-top: 10px;
      max-height: 250px;
    }
  }
}
