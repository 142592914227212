@use "src/style/main" as *;

.Environment {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    &-head {
      padding-right: 10px;
    }
  }
}
