@use "src/style/main" as *;

.ModalContainer {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(19, 35, 66, 0.09);
  backdrop-filter: blur(5px);
  padding: 30px 10px;
  overflow-y: auto;

  &--top {
    align-items: flex-start;
  }
  &--bottom {
    align-items: flex-end;
  }
  &--left {
    justify-content: flex-start;
  }
  &--right {
    justify-content: flex-end;
  }

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background: $white-color;
  }
  &::-webkit-scrollbar-thumb {
    background: $secondary-font-color;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: darken($secondary-font-color, 10%);
  }

  &__container {
    box-shadow: 0 4px 19px 0 rgba(0, 0, 0, 0.1);
    border-radius: 23px;
    width: fit-content;
    max-width: 100%;
  }
}
