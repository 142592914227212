@use "src/style/main" as *;

.Header {
  height: 75px;
  background-color: $white-color;
  box-shadow: 0 0 20px 4px rgba(206, 206, 206, 0.25);
  border: 0.1px solid rgba(19, 35, 66, 0.1);
  border-radius: 0 0 20px 20px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  z-index: 20;
  padding: 0 20px;

  &__left {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
  }

  &__logo {
    width: 136px;
    padding-bottom: 7px;
  }
  &__chevron {
    width: 30px;
    height: 10px;
    transform: rotate(90deg);

    &:hover {
      cursor: pointer;
    }
  }

  &__right {
    width: fit-content;
    height: 100%;
    display: flex;
    align-items: center;
  }
}

@include mobile {
  .Header {
    &__logo {
      width: 35px;
      padding-bottom: 0;
    }

    &__hamburger {
      width: 34px;
      height: 34px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
