@use "src/style/main" as *;

.HelpDropdown {
  position: relative;
  &__head {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    margin-right: 10px;
    border: 0.5px solid rgba(19, 35, 66, 0.3);
    padding: 2px;
    background-color: $white-color;
  }

  &:hover {
    cursor: pointer;
  }
  &__image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }
  &__name {
    font-family: "Manrope-Bold", sans-serif;
    color: $primary-font-color;
    margin: 0 15px 0 5px;

    &-active {
      color: $white-color;
    }
  }
  &__icon {
    height: 8px;
    margin-left: 10px;
  }

  &__body {
    position: absolute;
    width: fit-content;
    top: 65px;
    right: 0;
    min-width: 100%;
    border-radius: 10px;
    background-color: $white-color;
    border: 0.1px solid rgba(19, 35, 66, 0.1);
    box-shadow: 0 0 20px 4px rgba(206, 206, 206, 0.25);
    z-index: 100;
    &-item {
      font-family: "Manrope-Bold", sans-serif;
      text-align: center;
      padding: 10px 30px;
      white-space: nowrap;

      &:first-child {
        border-radius: 10px 10px 0 0;
      }
      &:last-child {
        border-radius: 0 0 10px 10px;
      }

      &:hover {
        background: linear-gradient(223deg, #aee7ff -6.49%, #030ac0 106.4%);
        color: $white-color;
      }
    }
  }
}

@include mobile {
  .HelpDropdown {
    &__name {
      //display: none;
    }
  }
}
