@use "src/style/main" as *;

.App {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
}

body {
  padding: 0;
  margin: 0;
  overflow-y: scroll;
  background-color: #f7f7f7;
  font-family: "Manrope-Bold", sans-serif;
  color: $primary-font-color;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background: $white-color;
  }
  &::-webkit-scrollbar-thumb {
    background: $secondary-font-color;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: darken($secondary-font-color, 10%);
  }
}

* {
  box-sizing: border-box;
}

.flex {
  display: flex;
  align-items: center;
}

.FloatingHelpButton {
  position: fixed;
  right: 20px; /* Distance from the right edge */
  bottom: 20px; /* Distance from the bottom edge */
  z-index: 2000; /* Ensure it stays above other elements */
  //box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;
}


