// ROBOTO ------------------------------------------------------------------------

@font-face {
  font-family: "Roboto";
  src:
    local("Roboto-Medium"),
    url("../../../src/assets/fonts/Roboto/Roboto-Medium.ttf") format("truetype");
}

// MURECHO ------------------------------------------------------------------------

//100
@font-face {
  font-family: "Murecho-Thin";
  src:
    local("Murecho-Thin"),
    url("../../../src/assets/fonts/Murecho/Murecho-Thin.ttf") format("truetype");
}

//200
@font-face {
  font-family: "Murecho-ExtraLight";
  src:
    local("Murecho-ExtraLight"),
    url("../../../src/assets/fonts/Murecho/Murecho-ExtraLight.ttf")
      format("truetype");
}

//300
@font-face {
  font-family: "Murecho-Light";
  src:
    local("Murecho-Light"),
    url("../../../src/assets/fonts/Murecho/Murecho-Light.ttf")
      format("truetype");
}

//400
@font-face {
  font-family: "Murecho-Regular";
  src:
    local("Murecho-Regular"),
    url("../../../src/assets/fonts/Murecho/Murecho-Regular.ttf")
      format("truetype");
}

//500
@font-face {
  font-family: "Murecho-Medium";
  src:
    local("Murecho-Medium"),
    url("../../../src/assets/fonts/Murecho/Murecho-Medium.ttf")
      format("truetype");
}

//600
@font-face {
  font-family: "Murecho-SemiBold";
  src:
    local("Murecho-SemiBold"),
    url("../../../src/assets/fonts/Murecho/Murecho-SemiBold.ttf")
      format("truetype");
}

//700
@font-face {
  font-family: "Murecho-Bold";
  src:
    local("Murecho-Bold"),
    url("../../../src/assets/fonts/Murecho/Murecho-Bold.ttf") format("truetype");
}

//800
@font-face {
  font-family: "Murecho-ExtraBold";
  src:
    local("Murecho-ExtraBold"),
    url("../../../src/assets/fonts/Murecho/Murecho-ExtraBold.ttf")
      format("truetype");
}

//900
@font-face {
  font-family: "Murecho-Black";
  src:
    local("Murecho-Black"),
    url("../../../src/assets/fonts/Murecho/Murecho-Black.ttf")
      format("truetype");
}

// MANROPE ------------------------------------------------------------------------

//200
@font-face {
  font-family: "Manrope-ExtraLight";
  src:
    local("Manrope-ExtraLight"),
    url("../../../src/assets/fonts/Manrope/Manrope-ExtraLight.ttf")
      format("truetype");
}

//300
@font-face {
  font-family: "Manrope-Light";
  src:
    local("Manrope-Light"),
    url("../../../src/assets/fonts/Manrope/Manrope-Light.ttf")
      format("truetype");
}

//400
@font-face {
  font-family: "Manrope-Regular";
  src:
    local("Manrope-Regular"),
    url("../../../src/assets/fonts/Manrope/Manrope-Regular.ttf")
      format("truetype");
}

//500
@font-face {
  font-family: "Manrope-Medium";
  src:
    local("Manrope-Medium"),
    url("../../../src/assets/fonts/Manrope/Manrope-Medium.ttf")
      format("truetype");
}

//600
@font-face {
  font-family: "Manrope-SemiBold";
  src:
    local("Manrope-SemiBold"),
    url("../../../src/assets/fonts/Manrope/Manrope-SemiBold.ttf")
      format("truetype");
}

//700
@font-face {
  font-family: "Manrope-Bold";
  src:
    local("Manrope-Bold"),
    url("../../../src/assets/fonts/Manrope/Manrope-Bold.ttf") format("truetype");
}

//800
@font-face {
  font-family: "Manrope-ExtraBold";
  src:
    local("Manrope-ExtraBold"),
    url("../../../src/assets/fonts/Manrope/Manrope-ExtraBold.ttf")
      format("truetype");
}

// MULISH ------------------------------------------------------------------------

//200
@font-face {
  font-family: "Mulish-ExtraLight";
  src:
    local("Mulish-ExtraLight"),
    url("../../../src/assets/fonts/Mulish/Mulish-ExtraLight.ttf")
      format("truetype");
}

//200
@font-face {
  font-family: "Mulish-ExtraLightItalic";
  src:
    local("Mulish-ExtraLightItalic"),
    url("../../../src/assets/fonts/Mulish/Mulish-ExtraLightItalic.ttf")
      format("truetype");
}

//300
@font-face {
  font-family: "Mulish-Light";
  src:
    local("Mulish-Light"),
    url("../../../src/assets/fonts/Mulish/Mulish-Light.ttf") format("truetype");
}

//300
@font-face {
  font-family: "Mulish-LightItalic";
  src:
    local("Mulish-LightItalic"),
    url("../../../src/assets/fonts/Mulish/Mulish-LightItalic.ttf")
      format("truetype");
}

//400
@font-face {
  font-family: "Mulish-Regular";
  src:
    local("Mulish-Regular"),
    url("../../../src/assets/fonts/Mulish/Mulish-Regular.ttf")
      format("truetype");
}

//400
@font-face {
  font-family: "Mulish-Italic";
  src:
    local("Mulish-Italic"),
    url("../../../src/assets/fonts/Mulish/Mulish-Italic.ttf") format("truetype");
}

//500
@font-face {
  font-family: "Mulish-Medium";
  src:
    local("Mulish-Medium"),
    url("../../../src/assets/fonts/Mulish/Mulish-Medium.ttf") format("truetype");
}

//500
@font-face {
  font-family: "Mulish-MediumItalic";
  src:
    local("Mulish-MediumItalic"),
    url("../../../src/assets/fonts/Mulish/Mulish-MediumItalic.ttf")
      format("truetype");
}

//600
@font-face {
  font-family: "Mulish-SemiBold";
  src:
    local("Mulish-SemiBold"),
    url("../../../src/assets/fonts/Mulish/Mulish-SemiBold.ttf")
      format("truetype");
}

//600
@font-face {
  font-family: "Mulish-SemiBoldItalic";
  src:
    local("Mulish-SemiBoldItalic"),
    url("../../../src/assets/fonts/Mulish/Mulish-SemiBoldItalic.ttf")
      format("truetype");
}

//700
@font-face {
  font-family: "Mulish-Bold";
  src:
    local("Mulish-Bold"),
    url("../../../src/assets/fonts/Mulish/Mulish-Bold.ttf") format("truetype");
}

//700
@font-face {
  font-family: "Mulish-BoldItalic";
  src:
    local("Mulish-BoldItalic"),
    url("../../../src/assets/fonts/Mulish/Mulish-BoldItalic.ttf")
      format("truetype");
}

//800
@font-face {
  font-family: "Mulish-ExtraBold";
  src:
    local("Mulish-ExtraBold"),
    url("../../../src/assets/fonts/Mulish/Mulish-ExtraBold.ttf")
      format("truetype");
}

//800
@font-face {
  font-family: "Mulish-ExtraBoldItalic";
  src:
    local("Mulish-ExtraBoldItalic"),
    url("../../../src/assets/fonts/Mulish/Mulish-ExtraBoldItalic.ttf")
      format("truetype");
}

//900
@font-face {
  font-family: "Mulish-Black";
  src:
    local("Mulish-Black"),
    url("../../../src/assets/fonts/Mulish/Mulish-Black.ttf") format("truetype");
}

//900
@font-face {
  font-family: "Mulish-BlackItalic";
  src:
    local("Mulish-BlackItalic"),
    url("../../../src/assets/fonts/Mulish/Mulish-BlackItalic.ttf")
      format("truetype");
}
