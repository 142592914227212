@use "src/style/main" as *;

.NotFound {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;

  &__container {
    width: 100%;
    max-width: 450px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__number {
    font-family: "Murecho-Black", sans-serif;
    font-size: 120px;
  }

  &__text {
    font-size: 20px;
    margin-bottom: 50px;
  }
}
