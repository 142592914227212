//screen sizes

$screen-4k: 3800px;
$screen-2k: 2500px;
$screen-full-hd: 1700px;
$screen-hd: 1200px;
$screen-mobile: 700px;

@mixin mobile {
  @media only screen and (max-width: $screen-mobile) {
    @content;
  }
}
@mixin tablet {
  @media only screen and (min-width: $screen-mobile) and (max-width: $screen-hd) {
    @content;
  }
}
@mixin laptop {
  @media only screen and (min-width: $screen-hd) {
    @content;
  }
}
@mixin screen-fhd {
  @media only screen and (min-width: $screen-full-hd) {
    @content;
  }
}
@mixin screen-2k {
  @media only screen and (min-width: $screen-2k) {
    @content;
  }
}
@mixin screen-4k {
  @media only screen and (min-width: $screen-4k) {
    @content;
  }
}
