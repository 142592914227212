@use "src/style/main" as *;

.LoginModal {
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  &__container {
    width: fit-content;
    border-radius: 23px;
    background-color: $white-color;
    stroke-width: 0.1px;
    stroke: rgba(19, 35, 66, 0.39);
    filter: drop-shadow(0px 0px 20px rgba(206, 206, 206, 0.25));
    padding: 40px;
    position: relative;
    max-height: 90vh; /* Prevents modal from going over the screen height */
    min-width: 50vh;
    //min-height: 80vh;
    overflow-y: auto;
    @media (max-width: 600px) {
      padding: 20px;
      max-width: 50vh;
      max-height: 85vh;
      min-width: 20vh;
      min-height: 60vh;
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__close {
    position: absolute;
    right: 25px;
    top: 25px;
    &:hover {
      cursor: pointer;
    }
  }
  &__label {
    font-family: "Manrope-Bold", sans-serif;
    color: #132342;
    font-size: 20px;
    color: $primary-font-color;
    text-align: center;
    margin-bottom: 50px;
    line-height: 24px;
  }

  &__icon {
    display: block;
    margin: 20px auto 40px;
    width: 70px;
    height: 82px;
    object-fit: contain;
  }

  &__buttons {
    display: flex;
    align-items: center;
  }
  &__cancel-btn h4 {
    letter-spacing: 0.8px;
  }
  &__submit-btn h4 {
    letter-spacing: 0.8px;
  }
  &__cancel-btn--hide {
    display: none;
  }
}


.SignInForm {
  display: flex;
  align-items: center;
  height: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background: $white-color;
  }
  &::-webkit-scrollbar-thumb {
    background: $secondary-font-color;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: darken($secondary-font-color, 10%);
  }

  &__container {
    width: 100%;
  }
  &__title {
    font-family: "Manrope-Bold", sans-serif;
    color: $primary-font-color;
    text-align: left;
    margin-bottom: 40px;
  }

  &__google {
    &-spinner {
      width: 20px;
      padding: 3px;
      aspect-ratio: 1;
      border-radius: 50%;
      background: $black-color;
      --_m: conic-gradient(#0000 10%, #000),
      linear-gradient(#000 0 0) content-box;
      -webkit-mask: var(--_m);
      mask: var(--_m);
      -webkit-mask-composite: source-out;
      mask-composite: subtract;
      animation: l3 1s infinite linear;
    }
    @keyframes l3 {
      to {
        transform: rotate(1turn);
      }
    }

    &-container {
      width: 100%;
      background: $white-color;
      height: 69px;
      border: 1px solid #747775;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        cursor: pointer;
      }
    }
    &-icon {
      width: 25px;
      height: 25px;
      margin-right: 10px;
    }
    &-text {
      color: $black-color;
      font-family: "Roboto-Medium", sans-serif;
      letter-spacing: 0.3px;
    }
  }
  &__or {
    color: $secondary-font-color;
    text-align: center;
    margin: 15px 0;
    font-size: 14px;
  }

  &__input {
    margin-bottom: 20px;
    &-error {
      text-align: right;
      margin: 5px 10px;
      font-family: "Manrope-Bold", sans-serif;
      color: $error-color;
    }
  }

  &__forgotPassword {
    text-align: right;
    font-family: "Mulish-Bold", sans-serif;
    color: $primary-color;
    margin: 0 10px;

    &:hover {
      cursor: pointer;
    }
  }

  &__button {
    margin-top: 20px;
  }

  &__link {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 30px;

    &-text {
      font-family: "Manrope-Bold", sans-serif;

      & > span {
        font-family: "Manrope-ExtraBold", sans-serif;
        padding-left: 2px;
        color: $primary-color;
        text-decoration: underline;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

@include screen-2k {
  .SignInForm {
    &__container {
      margin-bottom: 15%;
    }
  }
}
@include mobile {
  .SignInForm {
    align-items: flex-start;
  }
}
