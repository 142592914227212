@use "src/style/main" as *;

.PublicApply {
  display: flex;
  //justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;       // instead of height: 100vh
  // height: auto;

  &__left {
    width: 40%;
    height: 100%;
    background: #efefef;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: $secondary-font-color;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: darken($secondary-font-color, 10%);
    }

    &-container {
      height: 100%;
      width: 100%;
      max-width: 510px;
      padding: 0 20px;
    }
  }

  &__right {
    width: 60%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: $secondary-font-color;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: darken($secondary-font-color, 10%);
    }

    &-mobile {
      width: 100%;
    }

    &-container {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 660px;
      padding: 0 20px;
    }

    &-head {
      height: 10%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &-body {
      height: 90%;
      width: 100%;
    }

    &-logo {
      width: 200px;
    }

    &-title {
      font-family: "Murecho-Black", sans-serif;
      color: $primary-font-color;
      margin-top: 25px;
    }
  }

  &__jobBenefits {
    display: flex;
    align-items: center;
    //justify-content: space-between;
    margin-bottom: 60px;
    padding: 0 28px;

    &-title {
      font-family: "Manrope-Bold", sans-serif;
      font-size: 20px;
      color: #122342;
      margin-bottom: 20px;
      margin-top: 20px;
      margin-right: 10px;

      &-container {
        display: flex;
        align-items: center;
      }
    }
    &-body {
      display: flex;
      flex-wrap: wrap;
    }

    &-footer {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      font-size: 12px;
      text-decoration: underline;
    }
    &-viewLess {
      margin: 0 10px;
      &:hover {
        cursor: pointer;
      }
    }
    &-viewMore {
      margin: 0 10px;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .JobBenefitsCart {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 150px; /* Set a minimum width for each item */
    border-radius: 10px;
    background: $white-color;
    border: 0.1px solid rgba(19, 35, 66, 0.1);
    filter: drop-shadow(0px 0px 20px rgba(206, 206, 206, 0.1));
    padding: 10px 15px;
    margin: 5px;
    color: $secondary-font-color;
    flex-shrink: 0; /* Prevent items from shrinking */

    &--active {
      background: rgba(74, 151, 255, 0.2);
      color: $primary-font-color;
    }

    &__icon {
      height: 15px;
    }

    &__label {
      font-family: "Manrope-Bold", sans-serif;
      font-size: 14px;
      margin-left: 10px;
      white-space: nowrap; /* Prevent text from wrapping */
    }
  }

  &__logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 15%;

    &-icon {
      width: 200px;
    }
  }

  &__organization-logo {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 10px;
    object-fit: contain;

    &-container {
      position: relative;
      display: flex;
      justify-content: start;
      align-items: start;
    }

  }
  &__organization-logo-mobile {
    width: 50px;
    height: 50px;
    background-color: $white-color;
    border-radius: 50%;
    margin-bottom: 10px;
    object-fit: contain;

    &-container {
      position: relative;
      display: flex;
      justify-content: start;
      align-items: start;
    }

  }

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 85%;
    position: relative;
    z-index: 5;

    &-text {
      font-family: "Murecho-Black", sans-serif;
      font-size: 57px;
      text-align: left;
      color: $primary-font-color;
      line-height: 100%;
      letter-spacing: -1.71px;
    }
  }

  &__circle {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: flex-end;

    &-background {
      height: 70%;
      position: absolute;
      right: -200px;
      opacity: 0.5;
      //transform: scaleX(-1);
    }

    &-icon {
      position: absolute;
      width: 160px;
      right: 35px;
      bottom: 70px;
    }
  }
}

@include screen-2k {
  .PublicApply {
    &__circle {
      &-background {
        right: -320px;
      }
      &-icon {
        width: 200px;
        right: 70px;
        bottom: 100px;
      }
    }
  }
}

@include tablet {
  .PublicApply {
    &__title {
      &-text {
        font-size: 45px;
      }
    }
  }
}

@include mobile {
  .PublicApply {
    &__right {
      &-logo {
        width: 130px;
      }

      &-title {
        font-size: 14px;
        margin-top: 20px;
      }
    }
  }
}

@media (max-width: 600px) {
  .PublicApply {
    flex-direction: column;
  }

  .PublicApply__left,
  .PublicApply__right {
    width: 100%;
    min-height: auto;  // allow them to grow
    height: auto;      // remove the forced 100%
  }
}

.EditShortTermJob {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px 0;
  min-height: 680px;
  height: 100%;
  border: 0.5px solid rgba(19, 35, 66, 0.1);
  box-shadow: 0 0 20px 4px rgba(206, 206, 206, 0.25);
  &__header {
    display: flex;
    flex-direction: column;
  }
  &__container {
    display: flex;
    border-bottom: 0.3px solid rgba(200, 200, 200, 0.3);
    padding: 0 25px;
  }
  &__container-item {
    font-size: 14px;
    line-height: 13.16px;
    font-family: "Manrope-Bold", sans-serif;
    color: #b2b2b2;
    margin-right: 30px;
    padding-bottom: 10px;
    cursor: pointer;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 1.2px;
      bottom: -1px;
      left: 0;
      background: linear-gradient(265.82deg, #aee6ff 44.76%, #030ac0 58.06%);
      transform-origin: bottom right;
      transition: transform 300ms ease-out;
      visibility: hidden;
      z-index: 2;
    }

    &--active {
      color: #132342;
      &::before {
        transform: scaleX(1);
        transform-origin: bottom left;
        visibility: visible;
      }
    }
  }
  &__buttons-wrapper {
    display: flex;
    align-items: center;
    padding: 20px;
  }
  &__buttons {
    max-width: 400px;
    width: 100%;
    display: flex;
    margin-left: auto;
  }
  &__cancel-btn {
    background: #7e838b;
    margin-left: 10px;
    margin-right: 10px;
  }
  &__default-title {
    font-family: "Manrope-Bold", sans-serif;
    font-size: 20px;
    color: $primary-font-color;
    padding: 0 20px 20px 20px;
  }
  &__header-wrapper {
    display: flex;
    align-items: center;
    padding: 25px 20px;
  }
  &__form {
    margin-top: 25px;
    padding: 0 20px;

    @media screen and (max-width: 600px) {
      //margin-top: 725px;
      padding: 0 20px;
    }

  }
  &__delete-button {
    justify-self: flex-start;
    margin-right: 10px;
    max-width: 195px;
  }
  &__item-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__item {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }
  &__label {
    font-size: 12px;
    line-height: 11px;
    font-family: "Manrope-Bold", sans-serif;
    color: #c6c6c6;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    margin-bottom: 5px;
  }
  &__value {
    font-size: 16px;
    line-height: 21px;
    gap: 10px;
    font-family: "Manrope-Bold", sans-serif;
    color: #122342;
    cursor: not-allowed;
    align-items: center;
    display: flex;
  }

  &__payment-not-specified {
    font-size: 12px;
    line-height: 21px;
    gap: 10px;
    font-family: "Manrope-Bold", sans-serif;
    color: $secondary-font-color;
    cursor: not-allowed;
    align-items: center;
    display: flex;
  }

  &__description {
    font-size: 16px;
    line-height: 22px;
    font-family: "Manrope-Bold", sans-serif;
    color: #122342;
    max-width: 550px;
    width: 100%;
  }
  &__images {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  &__image {
    width: 75px;
    height: 75px;
    object-fit: contain;
    margin: 5px;
    border-radius: 6px;
  }

  &__shift-container {
    width: 263px;
    height: 51px;
    border-radius: 11px;
    border: 1px solid #0000001a;
    background-color: #fafbfb;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    cursor: not-allowed;
  }
  &__shift-item {
    font-size: 14px;
    line-height: 15px;
    font-family: "Manrope-Bold", sans-serif;
    color: #122342;
  }
  &__shift-item-small {
    font-size: 12px;
    line-height: 13px;
    font-family: "Manrope-SemiBold", sans-serif;
    color: #122342;
  }
  &__input-container {
    position: relative;
    max-width: 400px;
    width: 100%;
    cursor: pointer;
  }
  &__input {
    box-shadow: 0px 0px 20px 4px #cecece40;
  }

  &__input-error {
    font-family: "Manrope-Bold", sans-serif;
    color: #dd291e;
    font-size: 11px;
    padding: 5px 10px 0 10px;
  }
  &__textarea {
    min-height: 280px;
    box-shadow: 0px 0px 20px 4px #cecece40;
    &::placeholder {
      color: $primary-font-color;
      font-weight: 400;
    }
  }
  &__textarea-desc {
    padding: 10px 0;
  }
  &__images {
    padding: 10px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    max-width: 420px;
  }
  &__image-preview-container {
    position: relative;
    width: 90px;
    height: 90px;
  }
  &__image-preview {
    margin: 5px;
    border-radius: 6px;
    &:hover {
      cursor: pointer;
    }
  }
  &__delete-icon {
    position: absolute;
    width: 35px;
    height: 35px;
    top: 0;
    left: -5px;
    cursor: pointer;
  }
  &__images-placeholder {
    width: 90px;
    height: 91px;
    border-radius: 6px;
    border: 0.1px solid rgba(19, 35, 66, 0.1);
    box-shadow: 0px 0px 20px 4px rgba(206, 206, 206, 0.2509803922);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 10px 5px 2px;
  }
  &__add-icon {
    width: 15px;
    height: 15px;
    transform: rotate(45deg);
  }
  &__title {
    font-size: 20px;
    line-height: 19px;
    font-family: "Manrope-Bold", sans-serif;
    color: #122342;
  }
  &__back-icon {
    width: 18px;
    height: 18px;
    rotate: 90deg;
    margin-right: 15px;

    &:hover {
      cursor: pointer;
    }
  }
  &__shift-container {
    width: 263px;
    height: 51px;
    border-radius: 11px;
    border: 1px solid #0000001a;
    background-color: #fafbfb;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
  }
  &__shift-item {
    font-size: 14px;
    line-height: 15px;
    font-family: "Manrope-Bold", sans-serif;
    color: #122342;
  }
  &__shift-item-small {
    font-size: 12px;
    line-height: 13px;
    font-family: "Manrope-SemiBold", sans-serif;
    color: #122342;
  }
}

@include mobile {
  .EditShortTermJob {
    &__buttons-wrapper {
      flex-direction: column-reverse;
    }
    &__delete-button {
      margin-top: 20px;
      margin-right: 0;
      max-width: 440px;
    }
    &__buttons {
      flex-direction: column-reverse;
      max-width: 460px;
      width: 100%;
      display: flex;
    }
    &__cancel-btn {
      margin-top: 20px;
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.LongTermJobCard {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 4px #cecece40;
  border: 0.1px solid rgba(200, 200, 200, 0.1);
  padding-bottom: 20px;
  position: relative;

  &__schedule {
  }
  &__daysSchedule {
    display: flex;
    overflow-x: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    padding: 0 20px 20px 20px;
    grid-column-gap: 1px;
    height: 85px;

    /* Hide scrollbar for Chrome, Safari, and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge, and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  &__daySchedule {
    min-width: 40px;
    text-align: center;
    box-shadow: 0px 0px 20px 4px #cecece40;
    border: 1px solid rgba(200, 200, 200, 0.3);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &-head {
      font-family: "Manrope-ExtraBold", sans-serif;
      font-size: 10px;
      padding: 5px;
      border-radius: 5px 5px 0 0;
      background-color: #f3f3f3;
      &-active {
        background-color: #1fdef7;
      }
    }
    &-body {
      font-size: 10px;
      padding: 5px;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 20px;
    border-bottom: 0.2px solid rgba(215, 215, 215, 0.2);
    &:hover {
      cursor: pointer;
    }
  }
  &__job-title {
    font-size: 18px;
    color: #132342;
    font-family: "Manrope-Bold", sans-serif;
    line-height: 17px;
    word-break: break-word;
    white-space: pre-wrap;
    -moz-white-space: pre-wrap;
    margin-right: 5px;
  }

  &__organization-name {
    font-size: 14px;
    color: $secondary-font-color;
    font-family: "Manrope-Bold", sans-serif;
    line-height: 17px;
    word-break: break-word;
    white-space: pre-wrap;
    -moz-white-space: pre-wrap;
    margin-right: 5px;
  }

  &__job-price {
    font-size: 18px;
    color: #132342;
    font-family: "Manrope-Bold", sans-serif;
    line-height: 20px;
  }
  &__body {
    padding: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__city {
    color: #132342;
    font-family: "Manrope-Bold", sans-serif;
    font-size: 10px;
    line-height: 9.4px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    border: 1px solid #030ac0;
    border-radius: 4px;
    padding: 5px 8px 4px;
  }
  &__applicants-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 60px;
    padding: 0 28px;
    p {
      font-size: 12px;
      line-height: 16px;
      color: #132342;
    }
  }
  &__status-icon {
    cursor: pointer;
  }
  &__applicants-status {
    font-size: 12px;
    line-height: 16px;
    color: #132342;
    margin-left: 5px;
  }
  &__clock-in-status {
    margin-left: 5px;
    &--assigned {
      color: #1fdef7;
    }
    &--in-progress {
      color: #3fff80;
    }
    &--did-not-attend {
      color: #dd291e;
    }
    &--in-progress-yellow {
      color: #ffc82e;
    }
    &--completed {
      color: #1fdef7;
    }
  }
  &__card-total {
    font-size: 14px;
    line-height: 1.69;
    font-family: "Manrope-Bold", sans-serif;
    color: #1fdef7;
    background-color: #131a28;
    box-shadow: 0px -1px 1px 0px #131a283b;
    text-transform: uppercase;
    padding: 0 10px;
    border-radius: 5px;
    text-transform: uppercase;
    width: fit-content;
  }
  &__job-status {
    &--assigned {
      color: #1fdef7;
    }
    &--in-progress {
      color: #3fff80;
    }
    &--did-not-attend {
      color: #dd291e;
    }
    &--in-progress-yellow {
      color: #ffc82e;
    }
    &--completed {
      color: #1fdef7;
    }
  }
  &__completed-icon {
    margin-right: 5px;
    margin-left: 0;
    width: 14.5px;
  }
  &__ongoing {
    color: #132342;
    font-family: "Manrope-Bold", sans-serif;
    font-size: 12px;
    line-height: 16px;
    position: relative;
    margin-left: 20px;
    &::before {
      content: "";
      position: absolute;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: #f8b533;
      top: 4px;
      bottom: 0;
      left: -10px;
    }
  }
  &__upcoming {
    color: #132342;
    font-family: "Manrope-Bold", sans-serif;
    font-size: 12px;
    line-height: 16px;
    position: relative;
    margin-left: 20px;
    &::before {
      content: "";
      position: absolute;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: #030ac0;
      top: 4px;
      bottom: 0;
      left: -10px;
    }
  }
  &__upcoming--started {
    color: #132342;
    font-family: "Manrope-Bold", sans-serif;
    font-size: 12px;
    line-height: 16px;
    position: relative;
    margin-left: 20px;
    &::before {
      content: "";
      position: absolute;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: $error-color;
      top: 4px;
      bottom: 0;
      left: -10px;
    }
  }
  &__completed {
    color: #132342;
    font-family: "Manrope-Bold", sans-serif;
    font-size: 12px;
    line-height: 16px;
    position: relative;
    margin-left: 20px;
    &::before {
      content: "";
      position: absolute;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: #03c02d;
      top: 4px;
      bottom: 0;
      left: -10px;
    }
  }

  &__show-btn {
    position: absolute;
    bottom: 20px;
    right: 20px;
    //margin-left: 20px;
    width: 89%;
    height: 38px;
    padding: 10px 6px;
    border-radius: 7px;
    background: #206dd3;
    border: 0.5px solid rgba(19, 35, 66, 0.1);
    box-shadow: 0 0 20px 4px rgba(206, 206, 206, 0.25);
    text-transform: uppercase;
    &--center {
      right: 25%;
      left: 25%;
    }
    h4 {
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 0.8px;
      color: white;
    }
    &:hover {
      cursor: pointer;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
  }

  &__shift-card-body-another-shift {
    background-color: transparent;
    border-radius: 8px;
    padding: 6px 10px;
    height: 20px;
    margin: 0 20px -25px;
    box-shadow: 0px 0px 20px 4px #cecece40;
    border: 0.1px solid rgba(19, 35, 66, 0.1);
    z-index: 0;
  }
  &__shift-card-body {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 6px 10px;
    margin: 10px 20px 15px;
    box-shadow: 0px 0px 20px 4px #cecece40;
    border: 0.1px solid rgba(19, 35, 66, 0.1);
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__shift-card-start,
  &__shift-card-end {
    font-size: 12px;
    line-height: 13px;
    font-family: "Manrope-Bold", sans-serif;
    text-transform: uppercase;
  }
  &__person-img {
    width: 10px;
    height: 12px;
    margin-right: 5px;
  }
  &__applicants-images {
    display: flex;
    align-items: center;
    padding-left: 25px;
  }
  &__person-image-wrappper {
    width: 38px;
    height: 38px;
    margin-right: 5px;
    border: 1px solid #1e2ecb;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }
  &__applicant-img {
    padding-top: 5px;
    width: 29px;
    height: 42px;
  }
  &__applicants {
    display: flex;
    align-items: center;
    p {
      font-size: 12px;
      line-height: 16px;
      color: #132342;
    }
  }
  &__shift-card-total {
    font-size: 10px;
    line-height: 11px;
    font-family: "Manrope-Bold", sans-serif;
    color: #1fdef7;
    background-color: #131a28;
    box-shadow: 0px -1px 1px 0px #131a283b;
    text-transform: uppercase;
    padding: 3px 5px;
    border-radius: 5px;
  }
  &__edit-button {
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: fit-content;
    height: 38px;
    padding: 10px 6px;
    border-radius: 7px;
    background: $white-color;
    border: 0.5px solid rgba(19, 35, 66, 0.1);
    box-shadow: 0 0 20px 4px rgba(206, 206, 206, 0.25);
    text-transform: uppercase;
    h4 {
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 0.8px;
      color: $primary-font-color;
    }
    &:hover {
      cursor: pointer;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
  }
  &__pay-button {
    color: #132342;
    font-family: "Manrope-Bold", sans-serif;
    font-size: 10px;
    line-height: 9.4px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    border: 1px solid $error-color;
    border-radius: 4px;
    padding: 5px 8px 4px;
    cursor: pointer;
    margin-left: 20px;
  }
  &__processing-text {
    color: #132342;
    font-family: "Manrope-Bold", sans-serif;
    font-size: 10px;
    line-height: 9.4px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    border: 1px solid $warning-color;
    border-radius: 4px;
    padding: 5px 8px 4px;
    //cursor: pointer;
    margin-left: 20px;
    align-items: center;
    display: flex;
  }


  &__applicants-text {
    background-color: #f8ff00;
    padding: 2px;
  }
  &__available-job-applicants-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__available-job-hours-count {
    display: flex;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
    span {
      font-size: 12px;
      padding: 0 2px;
      background-color: #fe8585;
    }
  }
  &__clock-icon {
    width: 13px;
    height: 13px;
    margin-right: 2px;
  }
}
